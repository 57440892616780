import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, Body, NewsItem, Content,PressKitCard,
  Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import newsBG from '../assets/image/bg-news.jpg';

const PageTemplate = (props) => {
  const currentYear = new Date().getFullYear();
  return (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header 
      {...props} />
      <Hero bg={newsBG} 
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                Press releases
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pb-7 pt-4'>
        <Content>
        <Container className='p-lg-0 p-2'>
          
              {
                _.map(props.pressKits, (pressKit) => (
                  <Row>
                    <Col xs={12} className='p-0 mb-3'>
                        <PressKitCard {...pressKit} />
                    </Col>
                  </Row>
                ))
              }
              {/* <div className='pt-3'>
                {
                  _.times(currentYear - 2016, item =>  (
                    <Link to={`/news-archive/${currentYear-item}`} className='mr-2 t-link font-weight-bold color-base-dark animated-underline'>{currentYear - item}</Link>
                  ))
                }
              </div> */}
          
        </Container>
        </Content>
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)};

export default PageTemplate;