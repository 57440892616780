import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, AnimatedTitle, Body, HTMLContent, Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';


const PageTemplate = (props) => (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header {...props} />
      <Hero bg={props.featuredImage} 
        altText={props.featuredImageAltText}
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                <AnimatedTitle title={_.get(props, 'title')} />
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pb-7 pt-4' tabIndex={0}>
        <Container className='p-0'>
          <Row>
            <Col xs={12} lg={10} className='p-2 p-lg-0'>
              <HTMLContent className={`content ${_.get(props, 'slug')}`} content={_.get(props, 'content')} />
            </Col>
          </Row>
        </Container>
        
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
);

export default PageTemplate;