import React from 'react';

export default (props) => (
  <div class="py-4 border-b-1 border-b-gray-medium d-flex flex-lg-row flex-column">
    <div class="px-0 pr-lg-4 d-block d-lg-block pb-lg-0 pb-2">
      <img src={props.srcLogo} alt={props.srcTitle} class="w-80px w-md-210px blend-mode-multiply"/>
    </div>
    <div class="flex-grow-1 mb-n2">
        <h3 className="t-captionbody text-uppercase font-weight-bold pb-3">{props.datePublished}</h3>
        <p>
          <a href={props.url} dangerouslySetInnerHTML={{__html: props.title}} />
        </p>
    </div>
  </div>
);