import { combineReducers } from 'redux';
import pageReducer from './page';
import newsReducer from './news';
import teamReducer from './team';
import caseStudyReducer from './caseStudy';
import jobPostingReducer from './jobPosting';
import toolkitReducer from './toolkit';
import bannerReducer from './banner';

const rootReducer = combineReducers({
  banner: bannerReducer,
  page: pageReducer,
  news: newsReducer,
  team: teamReducer,
  caseStudy: caseStudyReducer,
  jobPosting: jobPostingReducer,
  toolkit: toolkitReducer,
});

export default rootReducer;