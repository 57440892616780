import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { retrieveToolkit } from '../actions/wp';
import { ToolkitTemplate } from '../templates';
import helper from '../helper';

class ToolkitContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: null,
      activeSectionTitle: null,
      activeSubsection: null, 
    }
  }

  componentDidMount = () => {
    this.props.retrieveToolkit(this.props.slug);
    window.addEventListener('scroll', this.getActiveSection, false)
    document.body.addEventListener('scroll', this.getActiveSection, false)

    this.getActiveSection();  
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.content && this.props.content !== null) {
      this.getActiveSection()
    }
  }

  removeTabIndex = ({target}) => {
    target.removeAttribute('tabindex')
  }

  jumpTo = (slug) => {
    const target = document.getElementById(slug);
    target.setAttribute("tabindex", 0);
    target.onblur = this.removeTabIndex;
    target.scrollIntoView({ behavior: 'smooth'});
    setTimeout(() => {
      target.focus({preventScroll:true});
    }, 0);
  }

  getActiveSection = () => {
    // Get Active H1
    const activeH1 = helper.getActiveSection('.content h1');
    if(activeH1) {
      this.setState({ 
        activeSection: activeH1.getAttribute("id"), 
        activeSectionTitle: activeH1.textContent 
      });
    }

    const activeH2 = helper.getActiveSection('.content h2');
    if(activeH2) {
      this.setState({ 
        activeSubsection: activeH2.getAttribute("id"), 
      });
    }
  }

  render = () => {
    return (
      <ToolkitTemplate 
        {...this.props}
        {...this.state}
        pageTitle={this.props.title}
        jumpTo={this.jumpTo}  
      />
    )
  }
}

const mapStateToProps = ({ toolkit }) => ({
  title: _.get(toolkit, 'activeToolkit.title'),
  content: _.get(toolkit, 'activeToolkit.content'),
  photo: _.get(toolkit, 'activeToolkit.photo')
});

export default connect(mapStateToProps, {
  retrieveToolkit
})(ToolkitContainer);