import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import JobPostingTemplate from '../templates/JobPostingTemplate';
import { retrieveJobPosting } from'../actions/wp';
import * as qs from 'query-string';

class JobPostingContainer extends React.Component {

  componentDidMount = () => {
    this.props.retrieveJobPosting(this.props.slug);
  }

  getQuery = () => {
    return qs.parse(_.get(this.props, 'location.search', ''), { ignoreQueryPrefix: true })
  }

  render = () => (
    <JobPostingTemplate 
      pageTitle="Jobs" attribution={_.get(this.props, 'activeJobPosting.imageAttribution')}
      {...this.props} 
        {...this.getQuery()}
    />
  )
}

const mapStateToProps = (state) => ({
  activeJobPosting: _.get(state, 'jobPosting.activeJobPosting'),
});

export default connect(mapStateToProps, { 
  retrieveJobPosting
})(JobPostingContainer);