import React from 'react';
import _ from 'lodash';

const List = (props) => (
  <div className={`list ${props.className||''}`}>
    {
      _.get(props, 'data', []).map(
        (item, index) => {
          if (props.renderItem && typeof(props.renderItem) === "function") {
            return props.renderItem(item, index);
          }
          return <>{item}</>;
        }
      )
    }
  </div>
);

export default List;