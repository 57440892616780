import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { Page, Footer, Header, 
    HTMLContent, Body, Slogan, Attribution,
    T, Hero, List, Feedback
} from '../components';

import { Container, Row, Col } from 'react-bootstrap';

import { dateIcon, focusIcon, outputLinkHover,
        formatIcon, locationIcon, tagIcon } from '../assets/image';

const Tag = (props) => (
  <span className='tag d-inline-flex justify-content-start align-items-start bg-white brad-2 py-0x px-1x mt-1x mr-1x'>
    {props.children}
  </span>
)

export default (props) => (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header page="projects"  />
      <Hero bg={_.get(props, 'image')} 
        altText={_.get(props, 'imageAltText')}
        variant="tall" mode="dark-blue" className='pt-6' position="center">
        <Container>
          <Row><Col xs={12}>
            <T title isMonospace className='mb-2 color-white' html={_.get(props, 'title')}></T>
            <T subtitle className='color-white font-weight-normal text-transform-none'>{_.get(props, 'subtitle')}</T>
          </Col></Row>
        </Container>
      </Hero>

      {/** Meta Area */}
      <Body>
        <div className='bg-base-dark w-100'>
          <Container>
            <Row>
              <Col md={3} sm={6} xs={12} className='pt-2 pt-md-3'>
                
                  {!_.isNil(props.projectStatus) && 
                    <div className='border-t-1 border-t-white pt-0x pb-md-3 pb-2'>
                      <T h5 sm isMonospace className='color-white text-uppercase'>Status</T> 
                      <Tag>
                        <img src={dateIcon} role="presentation" aria-hidden="true"  alt="" /><T span className='t-tag text-uppercase pl-0x'>{_.unescape(_.get(props, 'projectStatus'))}</T>
                      </Tag>
                    </div>
                  }

                  {!_.isEmpty(props.locations) &&
                    <div  className='border-t-1 border-t-white pt-0x pb-md-3 pb-2'>
                      <T h5 sm isMonospace className='color-white text-uppercase'>Location</T>
                      <List 
                        data={props.locations}
                        renderItem={(item) => (
                          <Tag key={item.name}>
                            <img src={locationIcon} role="presentation" aria-hidden="true" alt="" />
                            <T span className='t-tag text-uppercase pl-0x flex-occupy' aria-label="decorative">{_.unescape(item.name)}</T>
                          </Tag>
                        )}
                      />
                    </div>
                  }
              </Col>
              {!_.isEmpty(props.focusAreas) &&
                <Col md={3} sm={6} xs={12}  className='pt-2 pt-md-3'>
                  
                  <div  className='border-t-1 border-t-white pt-0x pb-md-3 pb-2'>
                    <T h5 sm isMonospace className='color-white text-uppercase'>Focus Area</T>
                    <List 
                      data={props.focusAreas}
                      renderItem={(item) => (
                        <Tag key={item.name}>
                          <img src={focusIcon} role="presentation"  aria-hidden="true"  alt=""  />
                          <T span className='t-tag text-uppercase pl-0x flex-occupy'>{_.unescape(item.name)}</T>
                        </Tag>
                      )}
                    />
                  </div>   
                </Col>
              }
              {!_.isEmpty(props.tags) &&
              <Col md={3} sm={6} xs={12}  className='pt-2  pt-md-3'>
                    <div  className='border-t-1 border-t-white pt-0x pb-md-3 pb-2'>
                      <T h5 sm isMonospace className='color-white text-uppercase flex-occupy'>Tags</T>
                      <List 
                        data={props.tags}
                        renderItem={(item) => (
                          <Tag key={item.name}>
                            <img src={tagIcon} role="presentation" aria-hidden="true"  alt="" />
                            <T span className='t-tag text-uppercase pl-0x flex-occupy'>{_.unescape(item.name)}</T>
                          </Tag>
                        )}
                      />
                    </div>   
              </Col>
              }
              {!_.isEmpty(props.formats) &&
              <Col md={3} sm={6} xs={12}  className='pt-2 pt-md-3'>
                  <div  className='border-t-1 border-t-white pt-0x pb-md-3 pb-2'>
                    <T h5 sm isMonospace className='color-white text-uppercase'>Output Format</T>
                    <List 
                      data={props.formats}
                      renderItem={(item) => (
                        <Tag key={item.name}>
                          <img src={formatIcon} role="presentation" aria-hidden="true"  alt=""/>
                          <T span className='t-tag text-uppercase pl-0x flex-occupy'>{_.unescape(item.name)}</T>
                        </Tag>
                      )}
                    />
                  </div> 
              </Col>
              }

            </Row>
          </Container>
        </div>
      </Body>


      {/** Outputs */}
      {!_.isEmpty(props.outputs) &&
      <Body>
        
          <Container className='px-2 px-md-0'>
            <List 
              data={_.get(props, 'outputs', [])}
              className='w-100 d-inline-flex py-3 border-b-1 flex-column flex-md-row border-b-gray-medium'
              renderItem={(item, index) => (
                <a key={item.url} href={item.url}  
                  className={`brad-round 
                    bg-pop-2 p-2 border-3 border-pop-2 ml-0 ml-md-2 color-base-dark
                    hover-base-dark-border active-white-bg font-weight-bold mr-0
                    ${index === props.outputs.length - 1 ? 'mr-md-2' : 'mb-2'}  ${props.outputs.length-1} ${index} mb-md-0 w-100 w-md-auto text-center
                  `}>
                  <T isSansSerif span className='t-link'>{_.unescape(item.title)}&nbsp;<img src={outputLinkHover} style={{ 
                        width: 20,
                        marginTop: -7,
                        marginLeft: 5
                  }}  role="presentation" aria-hidden="true"  alt="" /></T>

                  <span className='screen-reader-only'>(Opens in New Tab)</span>
                </a>
              )}
            />
          </Container>
          
      </Body>
      }

      {/** Content */}
      <Body className='pb-7 section-counter-set'> 
        <div className="w-100">
          <HTMLContent className='content' content={props.content} />
        </div>
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)