import React from 'react';
import _ from 'lodash';
import { retrievePressKits } from '../actions/wp';
import { connect } from 'react-redux';
import { PressArchiveTemplate } from '../templates';

class PressKitContainer extends React.Component {

  componentDidMount = () => {
    this.props.retrievePressKits();
  }
  
  componentDidUpdate = (prevProps, prevState) => {

  }

  render = () => (
    <PressArchiveTemplate 
      {...this.props}
    />
  )
}

const mapStateToProps = (state) => ({
  pressKits: _.get(state.news, 'pressKits')
});

export default connect(mapStateToProps, {
  retrievePressKits
})(PressKitContainer);