import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { retrievePage } from '../actions/wp';
import { HomeTemplate } from '../templates';
import Error404Template from '../templates/Error404Template';


class HomeContainer extends React.Component {

  componentDidMount = () => {
    this.props.retrievePage(this.props.slug);
  }

  
  componentDidUpdate = (prevProps) => {
    if (prevProps.slug !== this.props.slug) {
      this.props.retrievePage(this.props.slug);
    }
  }

  renderTitle = () => {
    if(this.props.title) {
      return this.props.title;  
    } else {
      return null;
    }
    
  }

  render = () => {
    if (this.props.is404Error) {
      return <Error404Template />
    }

    return (
      <HomeTemplate
        {...this.props}
        pageTitle={this.props.pageTitle}
        attribution={this.props.imageAttribution}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  content: _.get(state.page, 'content'),
  title: _.get(state.page, 'title'),
  id: _.get(state.page, 'id'),
  excerpt: _.get(state.page, 'excerpt'),
  author: _.get(state.page, 'author'),
  featuredImage: _.get(state.page, 'featuredImage'),
  isLoading: _.get(state.page, 'isLoading'),
  imageAttribution: _.get(state.page, 'imageAttribution'),
  is404Error: _.get(state.page, 'is404Error')
});

export default connect(mapStateToProps, {
  retrievePage
})(HomeContainer);