import React from 'react';
import _ from 'lodash';
import { Provider } from 'react-redux';
import store from './store';

import MOCTOWebsite from './pages/MOCTOWebsite';

class App extends React.Component {
  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll, false);
    document.body.addEventListener('touchmove', this.onScroll, false);
    document.body.addEventListener("gesturechange", this.onScroll, false);
    document.addEventListener('click', this.checkLink);
  }

  checkLink = (event) => {
    const tag = event.target;
    if(tag.tagName === "A") {

      const location = tag.getAttribute("href");
      if(location && !location.match(/^(#|\.|\/)/g) 
          && !location.match(/\/\/www.*\.nyc\.gov/g)
          && !location.match(/\/\/www.{0,1}.*\.nyc\.gov/g)
          && !location.match(/\/\/.*\.nyc\.gov/g)
          && !location.match(/\/\/nyc\.gov/g)
          && !location.match(/mailto:.*/g)) {
            
        if(!window.confirm('You are about to leave NYC.gov. Please confirm')) {
          event.preventDefault();
        }
      }
    }
    return false;
  }

  onScroll = () => {
    var targetHeaders = document.querySelectorAll('.sticky-header');
    _.each(targetHeaders, item => {
      
      const target = [...item.childNodes].filter(ch => ch.classList !== undefined);
      if (target.length > 0 && target[0].classList !== undefined) {
        if(item.getBoundingClientRect().top - 70 < 0) {
          target[0].classList.add('sticky');
        } else {
          target[0].classList.remove('sticky');
        }


        const childHeight = target[0].getBoundingClientRect().height;
        if ( item.getBoundingClientRect().top - 70 + item.getBoundingClientRect().height - childHeight < 0) {
          target[0].classList.add('sticky-landing');
        } else {
          target[0].classList.remove('sticky-landing');
        }
      }  
    });

    var homepageImages = document.querySelectorAll('.anim-group-img');
    _.each(homepageImages, item => {
      
      const bounding = item.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        // bounding.left >= 0 &&
        // bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        !item.classList.contains("activated")
      ) {
        item.classList.add('activated');
      }
    })

  }

  render = () => (
  
    <Provider store={store}>
      <MOCTOWebsite />
    </Provider>
  )
};

export default App;
