import React from 'react';
import ToolkitHeader from './ToolkitHeader';
import DefaultHeader from './DefaultHeader';

export {
  ToolkitHeader,
  DefaultHeader
};

export default (props) => {

  if (props.variant === "toolkit") {
    return <ToolkitHeader {...props} />;
  }

  return (<DefaultHeader {...props} />)
};