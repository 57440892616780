import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, Body, NewsItem, Content, JobDescription, JobForm, Feedback
} from '../components';


import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import jobsImage from '../assets/image/jobs-hero.png';


const PageTemplate = (props) => {
  const currentYear = new Date().getFullYear();
  return (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header 
      {...props} />
      <Hero bg={jobsImage} 
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                {_.get(props, 'activeJobPosting.title')}
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pt-4'>
        <Container className='p-2 p-lg-0'>
          <Row>
            <Col xs={12} lg={9} className='p-0'>
              <Content>
                {
                  props.showForm === "true"
                    ? <JobForm {...props.activeJobPosting} />
                    : <JobDescription {...props.activeJobPosting} />
                }
              </Content>
            </Col>
          </Row>
        </Container>
        
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)};

export default PageTemplate;