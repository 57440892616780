import React from 'react';

export default (props) => {
  let variant = 'h-default';
  let pos, mode;
  switch (props.variant) {
    case 'tall': variant = 'h-tall'; break;
    case 'short': variant = 'h-short'; break;

    default: break;
  }

  switch (props.position) {
    case 'sw': pos = 'align-items-start justify-content-end'; break;
    case 'center': pos = 'align-items-center justify-content-center text-center'; break;
    default: pos = 'align-items-start justify-content-end'; break;
  }

  switch (props.mode) {
    case 'light': mode = "bg-cover-light"; break;
    case 'dark': mode = "bg-cover-dark"; break;
    case 'blue': mode = "bg-cover-blue"; break;
    case 'dark-blue': mode = "bg-cover-gray bg-cover-darkblue"; break;
    case 'light-blue': mode = "bg-cover-gray bg-cover-lightblue"; break;
    default: break;
  }

  return (
    <div 
      className={`hero flex-column position-relative w-100 d-flex pb-md-4 pb-1 ${pos} ${variant} ${props.className}`}
      // tabIndex={-1}
      // aria-label={props.altText}
      role="none"
    >
      <div title={props.title || ""} role="region" className={`position-relative w-100`} style={{ zIndex: 50}}>
        {props.children}
      </div>
      <div className={`w-100 position-absolute bg-cover ${mode}`} 
        aria-hidden={(props.bg  === undefined || props.photoImage === false) ? "true" : "false"}
        role="img"
        aria-label={props.altText}
        style={{ top: 0, zIndex: 0, left: 0, height: '100%',  backgroundImage: `url(${props.bg})`}}
      ></div>
    </div>
  )
};