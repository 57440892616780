import axios from 'axios';
// import cookie from 'react-cookie';


export const client = axios.create({
  baseURL: `https://nyctechstage.wpengine.com/wp-json/wp/v2`,
  responseType: 'json',
  // withCredentials: true,
});

// export const setAuthHeader = (token) => {
//   client.defaults.headers.common.Authorization = `Bearer ${token}`;
// };

// if (cookie.load('token') && !cookie.load('token')) {
//   setAuthHeader(cookie.load('ballista_token'));
// }
