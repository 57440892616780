import _ from 'lodash';
const defaultState = {
  content: null,
  title: null,
  id: null,
  slug: null,
  excerpt: null,
  author: {},
  featuredImage: null,
  featuredImageAltText: null,
  isLoading: false,
  imageAttribution: null,
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case 'WORDPRESS_RETRIEVE_PAGE': 
      return ({
        ...defaultState,
        isLoading: true,
        is404Error: false,
      });
    ;

    
    case 'WORDPRESS_RETRIEVE_PAGE_SUCCESS':{
      const response = _.get(action, 'payload.data[0]');


      if (!response) {
        return ({
          ...state, 
          isLoading: false,
          is404Error: true,
        })
      }
      return ({
        ...state, 
        content: _.get(response, 'content.rendered'),
        title: _.get(response, 'title.rendered'),
        id: _.get(response, 'id'),
        slug: _.get(response, 'slug'),
        excerpt: _.get(response, 'excerpt.rendered'),
        is404Error: false,
        author: _.get(response, '_embedded.author', []).map(item=>
          ({
            id: item.id, 
            name: item.name, 
            avatar: item.avatar_urls
          })
        ),
        featuredImage: _.get(response, '_embedded.wp:featuredmedia[0].source_url'),
        featuredImageAltText: _.get(response, '_embedded.wp:featuredmedia[0].alt_text'),
        imageAttribution: _.get(response, 'acf.image_attribution'),
        isLoading: false,
      })
    };

    case 'WORDPRESS_RETRIEVE_PAGE_ERROR':{
      const response = _.get(action, 'payload.data[0]');

    }

    default: 
      return state
  }
};