import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, AnimatedTitle, Body, HTMLContent, ToolkitHeader, Content, Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';
import Sidebar from '../components/toolkit/ToolkitSidebar';


const PageTemplate = (props) => (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header {...props} />
      <main id="maincontent">
        <Body className='pt-4'>
        <Container fluid className='px-0'>
            <Row>
              <Col lg={4} xl={3} className='d-none d-lg-block sticky-header'>
                  <Sidebar 
                    content={props.content} 
                    jumpTo={props.jumpTo}
                    activeSection={props.activeSection}
                    activeSubsection={props.activeSubsection}
                    title={props.title}
                  />
              </Col>
              <Col lg={{span: 7, offset: 1}} xl={{span: 6, offset: 1}} xs={12} className='pb-4 pb-lg-6'>
                <Content variant="html" className='main-content toolkit-content' content={props.content}/>
              </Col>
            </Row>
          </Container>
        </Body>
        <Feedback />
        <Slogan />
        { props.attribution && 
          <Attribution attribution={props.attribution} />
        }
        <Footer />
      </main>
    </StickyContainer>
  </Page>
);

export default PageTemplate;