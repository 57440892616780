import React, { useState } from 'react';
import { Sticky } from 'react-sticky';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import Logo from '../Logo';
import NotificationBanner from '../NotificationBanner';
import menuBurger from '../../assets/image/menu-burger.svg';
import closeMenuImage from '../../assets/image/close-menu.svg';



const MENU_ITEMS = [
  { link: "/about", page: "about", label: "About"},
  { link: "/collaboration", page: "work-with-us", label: "Collaboration"},
  { link: "/projects", page: "projects", label: "Projects"},
  { link: "/news", page: "news", label: "News"},
  { link: "/contact", page: "contact", label: "Contact"},
  { link: "/jobs", page: "jobs", label: "Jobs"},
];
const Header = ({page, ...props}) => {
  
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Sticky className={`sticky-container z-top`}>
      {({ style, distanceFromTop}) => {
        const dist = parseInt(distanceFromTop)
        return  (
        <div style={{zIndex: 1020,...style}} className='top-nav-bar'>


          <Navbar className='p-0'>
            <NotificationBanner hide={dist < -30} />
          </Navbar>

          <Navbar 
            className='p-0 bg-white shadow-sm align-items-start' 
            expand="lg" 
            onToggle={(expanded) => {
              setExpanded(expanded);
            }}
            expanded={isExpanded}
            style={{zIndex: 5000}}
          >
            <Container>
              <Row>
                <Col xs={12} className={`px-1 px-lg-0 ${props.containerClass||''}`}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <Navbar.Brand>
                      <Logo scrolled={dist < -30} className='d-inline-block'/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className='border-0 py-1 px-0 d-md-none' >
                      <img src={menuBurger} alt=""/>
                    </Navbar.Toggle>

                    <Nav className='d-none d-md-flex flex-row'>
                    {
                      MENU_ITEMS.map(menu => (
                        <Nav.Item key={`key-${menu.link}`} className='pl-2 pl-lg-3'>
                          <Link to={menu.link} className={`nav t-nav hover-bold color-base-dark justify-content-end ${page === menu.page ? 'border-b-5 border-b-pop-1 font-weight-bold' : ''}`}>{menu.label}</Link>
                        </Nav.Item>
                      ))
                    }
                    </Nav>
                  </div>
                </Col>
              </Row>
            </Container>



            <div className='position-absolute w-100 d-block d-md-none'>
              <Navbar.Collapse id="responsive-navbar-nav" 
                className={`align-items-end p-0 p-2 bg-pop-2`}>
                <Nav>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" className='border-0 text-right px-0 pb-1'>
                    <img src={closeMenuImage} alt=""/>
                  </Navbar.Toggle>
                {
                  MENU_ITEMS.map(menu => (
                    <Nav.Item key={`key-${menu.link}`} className='py-2 px-0'>
                      <Link onClick={()=>{ setExpanded(false); }} to={menu.link} className={`nav color-base-dark is-monospace t-md text-uppercase justify-content-end  ${page === menu.page ? 'font-weight-bold' : ''}`}>{menu.label}</Link>
                    </Nav.Item>
                  ))
                }
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
    ) }}
    </Sticky>
  )
};

export default Header;
