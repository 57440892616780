import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const getSections = (content) => {
  const sections = content.match(/<h.\s+id="(.*?)">(.+?)\s*<\/h/g);
  const subsections = sections && sections.reduce(
    (s, currVal) => {
      var h1 = currVal.match(/<h1 id="(.*?)">(.*?)<\/h/);
      var h2 = currVal.match(/<h2 id="(.*)">(.*?)<\/h/);

      if (h1) {
        s.push({ heading: { id: h1[1], title: h1[2] }, subheading: [] });
      } else if (h2) {
        s[s.length - 1].subheading.push({ id: h2[1], title: h2[2] })
      }
      return s;
    }, []
  );


  return subsections ? subsections : [];

}


export default (props) => {
  const sections = props.content ? getSections(props.content) : [];

  const jumpTo = (target) => {
    if (target != null) {
      props.jumpTo(target);
      if (props.onClick && typeof props.onClick === "function") {
        props.onClick();
      }
    }
  }

  return (
    <nav
      className={`toolkit-sidebar overflow-hidden pt-lg-5 pt-2 pl-lg-2 pl-0 w-100 w-lg-auto`}
      role="navigation"
      style={{ 
        width: "320px !important",
      }}
    >
      <span className="sr-only">Table of Contents</span>
      <Link onClick={() => jumpTo(_.get(sections, '[0].heading.id', null)) } class="sr-only sr-only-focusable">Skip to main content</Link>
      <ol className={`toc ${props.className} ${props.isMenuVisible ? 'expanded' : ''}`}>
        {
          sections.map(
            section => (
              <li
                key={section.heading.id}
                data-active={props.activeSection === section.heading.id}
                className='text-uppercase is-monospace font-0x pb-2 px-2 hover-pop-1'
              >
                <Link className='text-uppercase font-weight-bold color-base-dark hover-pop-1'
                  onClick={() => {
                    jumpTo(section.heading.id)
                  }} role="button"
                  dangerouslySetInnerHTML={{ __html: section.heading.title }}
                />
                {
                  section.subheading.length > 0 &&
                  <ul className='list-unstyled p-0'>
                    {
                      section.subheading.map(
                        subsection => (
                          <li
                            key={subsection.id}
                            className='subsec is-monospace font-0x pt-1'
                            data-active={props.activeSubsection === subsection.id}
                          >
                            <Link className='text-uppercase hover-pop-1 color-base-dark' onClick={() => jumpTo(subsection.id)}
                              role="button"
                              dangerouslySetInnerHTML={{ __html: subsection.title }}
                            />
                          </li>
                        )
                      )
                    }
                  </ul>
                }
              </li>
            )
          )
        }
      </ol>

    </nav>
  );
}