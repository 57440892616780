import React from 'react';
import _ from 'lodash';

import { 
  Home,
  Page, 
  TeamBio, 
  Project,
  PressKit,
  PressRelease,
  ProjectArchive,
  FeaturedProjects,
  NewsArchive,
  Toolkit,
  Publication,
  GenericPage } from '../containers';

import JobPostingPage from './JobPostingPage';

import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import ScrollToSomewhere from '../components/layouts/ScrollToSomewhere';
import GA from '../components/GoogleAnalytics';


class MOCTOWebsite extends React.Component {


  render = () => (
    <Router>
      { GA.init() && <GA.RouteTracker /> }
      <ScrollToSomewhere/>
      <Switch>
        <Route path="/" exact
          render={(props) => {
            const {href} = window.location
            
            if (href.indexOf("/%23") >= 0) {
              const redirect = href.replace("#/", "").replace("/%23", "#");
              window.location = redirect;
            }

            return (
              <Home {...props} slug="home" page="home" />
            )}
          }
        >
        </Route>


        <Route path="/about" exact 
          render={(props) => (
            <Page 
              {...props} 
              slug="about-us" 
              page="about"
              pageTitle={"About Us"} 
              className='about-page'
            />)}
        />

        <Route path="/collaboration" exact
          render={(props) => (
            <Page
              {...props}
              page="work-with-us"
              slug="work-with-us"
              pageTitle="Work with Us"
            />
          )}
        />
        <Route path="/projects" exact
          render={(props)=> (
            <FeaturedProjects 
              {...props}
              page="projects"
              slug="explore-our-work"
              pageTitle="Explore our Work"
            />
          )}
        />

        <Route path="/projects-all" exact
          render={(props)=> (
            <ProjectArchive
                {...props}
                page="projects"
                pageTitle="Explore our Work | All Projects"
            />
          )}
        />

        <Route path="/projects-all/:query" exact
          render={(props)=> (
            <ProjectArchive
                {...props}
                page="projects"
                pageTitle="Explore our Work | All Projects"
                query={_.get(props.match, 'params.query')}  
            />
          )}
        />

        <Route path="/news" exact
          render={(props) => (
            <Page 
              {...props} page="news" 
              slug="latest-news"
              pageTitle="News About Us"
              className='latest-news-page'
            />
          )}
        />
        <Route path="/contact" exact
          render={(props) => (
            <Page 
              {...props} slug="get-in-touch" 
              pageTitle="Contact Us" 
              className='main-content'
              page='contact'
            />
          )}
        />

        <Route path="/jobs" exact
          render={(props) => (
            <Page 
              {...props} 
              slug="jobs" 
              page="jobs"
              className='jobs-page' 
            /> 
          )}
        />

        <Route path="/covid-19-response" exact
          render={(props) => (
            <Page {...this.props} 
              page="covid-19-response"
              slug="covid-19-response" 
              className='toolkits-page' 
            />
          )}
        />

        <Route path="/jobs/:slug"
          render={(props) => (
            <JobPostingPage  
              {...props} 
              page="jobs"
              slug={_.get(props.match, 'params.slug')}  
            />) }
        />
        <Route 
          path="/project/:slug"
          render={(props) => (
            <Project {...props} 
              action="view" 
              slug={_.get(props.match, 'params.slug')}  
              page="projects" 
            />)
          }
        />
        <Route 
          path="/press-release/:slug"
          render={(props) => (
            <PressRelease 
              {...props} 
              action="view" 
              slug={_.get(props.match, 'params.slug')}  
              page="news" 
            />
          )}
        />
        <Route
          path="/press" exact
          render={
            (props) => <PressKit page="news" {...props} />
          }
        />
        <Route path="/press-kit" exact>
          <Redirect to="/press" />
        </Route>

        <Route
          path="/news-archive"  exact
          render={
            (props) => <NewsArchive {...props} year={`${new Date().getFullYear()}`}  page="news" />
          }
        />
        <Route
          path="/news-archive/:year" exact
          render={
            (props) => <NewsArchive {...props}  year={_.get(props, 'match.params.year')}  page="news" />
          }
        />
        <Route
          path="/team" exact
          render={(props)=><Page {...props} slug="meet-the-team" className='meet-the-team' pageTitle="Meet the Team" page="about" />}
        />
        <Route
          path="/team/:slug" exact
          render={(props)=>( <TeamBio slug={_.get(props.match, 'params.slug')}  page="about" /> )}
        />
        <Route
          path="/toolkit/:slug"
          exact
          render={(props)=><Toolkit slug={_.get(props.match, 'params.slug')}  page="toolkit" />}
        />

        <Route
          path="/publication/:slug"
          exact
          render={(props)=><Publication slug={_.get(props.match, 'params.slug')}  page="publication" />}
        />

        <Route
          path="/:slug"
          render={(props)=>{
            return <GenericPage slug={_.get(props.match, 'params.slug')} page="all" />
          }}
        />
      </Switch>
    </Router>
  )
}

export default MOCTOWebsite;