import React from 'react';
import { Link } from 'react-router-dom';
import moctoLogoFull from '../assets/image/cto_logo-horizontal_dark-2x.png';
import moctoLogoShort from '../assets/image/cto_logo-short_dark-2x.png';

const Logo = (props) => {
  if (props.full) {
    return (
      <Link to="/" className={`cto-logo position-relative ${props.full ? 'full' : ''} ${props.className||''}`}>
        <img src={moctoLogoFull} 
          height={props.height || 50}
          className='mocto-logo-full' 
          alt="Mayor's office of the chief technology officer logo" 
        />
      </Link>
    )
  } else {
    return (
      <Link to="/" className={`cto-logo position-relative animated-logo ${props.scrolled ? 'animated' : ''}  ${props.className||''}`}>
        <span className='d-block animated-logo-full'>
          <img src={moctoLogoFull} 
            height="100%"
            className='mocto-logo-full d-none d-lg-block' 
            alt="Mayor's office of the chief technology officer" 
            aria-hidden={props.scrolled ? "true" : "false"}
          />
        </span>
        <span className='d-block animated-logo-short'>
          <img 
            src={moctoLogoShort} 
            height="100%"
            className='mocto-logo-short d-block d-lg-block' 
            alt="Mayor's office of the chief technology officer" 
            aria-hidden={props.scrolled ? "false" : "true"}
          />
        </span>
      </Link>
    );    
  }
};

export default Logo;