import _ from 'lodash';
const defaultState = {
  activeCaseStudy: null, 
  caseStudies: [],
  parentCategories: [],
  categories: [],
  error: false,
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case "WORDPRESS_RETRIEVE_CASE_STUDY": {
      return ({
        activeCaseStudy: null,
        isLoading: true,
        error: false,
      })
    }

    case "WORDPRESS_RETRIEVE_CASE_STUDY_ERROR": {
      return ({
        isLoading: false,
        error: true
      })
    }

    case "WORDPRESS_RETRIEVE_CASE_STUDY_SUCCESS": {
      
      const caseStudy = _.get(action, 'payload.data[0]');
      
      if (!caseStudy || caseStudy.length == 0) {
        return ({
          ...state,
          isLoading: false,
          error: true,
        });
      }
      
      return ({
        ...state,
        isLoading: false,
        activeCaseStudy: {
          id: _.get(caseStudy, 'id'),
          title: _.get(caseStudy, 'title.rendered'),
          slug: _.get(caseStudy, 'slug'),
          content: _.get(caseStudy, 'content.rendered'),
          image: _.get(caseStudy, '_embedded.wp:featuredmedia[0].source_url'),
          imageAltText: _.get(caseStudy, '_embedded.wp:featuredmedia[0].alt_text'),
          categories: _.get(caseStudy, 'case_study_categories', []),
          tags: _.get(caseStudy, '_embedded.wp:term[0]', []).map(item=>({ id: item.id, name: item.name })),
          subtitle: _.get(caseStudy, 'acf.subtitle'),
          isActive: _.get(caseStudy, 'acf.is_active'),
          dateStart: _.get(caseStudy, 'acf.date_start'),
          dateEnded: _.get(caseStudy, 'acf.date_ended'),
          projectStatus: _.get(caseStudy, 'acf.project_status', "Ongoing"),  
          imageAttribution  : _.get(caseStudy, 'acf.image_attribution'),
          outputs: _.compact([_.get(caseStudy, 'acf.external_link_1', null), 
                    _.get(caseStudy, 'acf.external_link_2', null), 
                    _.get(caseStudy, 'acf.external_link_3', null), 
                    _.get(caseStudy, 'acf.external_link_4', null) , 
                    _.get(caseStudy, 'acf.external_link_5', null)])
        }
      })
    }

    case "WORDPRESS_RETRIEVE_CASE_STUDIES_SUCCESS": {
      
      return ({
        ...state,
        caseStudies: _.get(action, 'payload.data').map(item=>({
          id: _.get(item, 'id'),
          title: _.get(item, 'title.rendered'),
          subtitle: _.get(item, 'acf.subtitle'),
          slug: _.get(item, 'slug'),
          content: _.get(item, 'content.rendered'),
          image: _.get(item, '_embedded.wp:featuredmedia[0].source_url'),
          imageAltText: _.get(item, '_embedded.wp:featuredmedia[0].alt_text'),
          topics: _.get(item, '_embedded.wp:term[0]').map(term => ({
            id: term.id,
            name: term.name,
          })),
          categories: _.get(item, '_embedded.wp:term[1]').map(term => ({
            id: term.id,
            name: term.name,
            slug: term.slug,
            parent_id: term.parent
          }))
        }))
      })
    }

    case "WORDPRESS_RETRIEVE_CASE_STUDY_CATEGORIES_SUCCESS": {

      const categories = _.get(action, 'payload.data');
      
      return ({
        ...state, 
        parentCategories: categories.filter(cat=>cat.parent === 0).map(item => ({
          id: item.id,
          name: item.name, 
          slug: item.slug,
        })),
        categories: categories.filter(cat=>cat.parent !== 0).map(item => ({
          id: item.id,
          name: item.name, 
          slug: item.slug,
          parent_id: item.parent
        })),
      });
    }

    case "WORDPRESS_RETRIEVE_CASE_STUDY_TOPICS_SUCCESS": {
      const topics = _.get(action, 'payload.data');
      return ({
        ...state, 
        topics: topics.map(item => ({
          id: item.id,
          name: item.name, 
          slug: item.slug,
          parent_id: null
        }))
      })
    }

    default: return state;
  }
};