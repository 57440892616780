import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, AnimatedTitle, Body, HTMLContent, Feedback
} from '../components';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const PageTemplate = (props) => (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header {...props} />
      <Hero bg={props.featuredImage} 
        altText={props.featuredImageAltText}
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                <AnimatedTitle title={_.get(props, 'title')} />
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='bg-gray-light'>
        <Container className='border-b-1 border-b-gray-medium'>
          <Row>
            <Col xs={12} className='d-flex py-3 px-2 px-lg-0'>
              <Link to='/projects' className='cursor-default mr-2 brad-round border-3 py-2 px-3 border-base-dark color-base-dark t-link'>Highlights</Link>
              <Link to='/projects-all' className='border-3 brad-round py-2 px-3 border-pop-2 bg-pop-2 hover-base-dark-border color-base-dark t-link'>View All</Link>
            </Col>
          </Row>
        </Container>
      </Body>
      <Body className='pb-7 pt-4'>
        <Container className='p-0'>
          <Row>
            <Col xs={12} className='p-lg-0 p-2'>
              <HTMLContent content={_.get(props, 'content')} />
            </Col>
          </Row>
        </Container>
        
      </Body>
      
      <Feedback />
      
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
);

export default PageTemplate;