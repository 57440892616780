import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import T from './Typography';
import { tagIcon, focusIcon } from '../assets/image';
const Tag = (props) => (
  <T xs span className='tag t-tag cursor-default color-base-dark is-monospace text-uppercase d-inline-block justify-content-start align-items-stsart has-monospace brad-2 border-base-dark border-1 py-0x px-1x mt-1x mr-1x text-nowrap'>
    {props.children}
  </T>
)

export default(props) => (
  <div className='w-100 mt-4'>
    <Link to={`/project/${props.slug}`} className="hover-pop-1 work-item animated-block">
      <div className='w-100 bg-cover bg-sq bg-cover-lightblue' 
        style={{backgroundImage: `url(${props.image})`}}
        role="img"
        title={props.imageAltText}
      >
        <span className="work-caption cursor-default h-100 font-1 is-sansserif position-absolute bottom left px-1 bg-white-translucent border-t-3 border-t-pop-1 color-base-dark">
            {_.get(props, 'subtitle')}
        </span>
      </div>  
      <T className='t-captionhead color-base-dark font-weight-bold py-1 hover-pop-1 d-block'><span dangerouslySetInnerHTML={{__html: props.title }}></span></T>
    </Link>
    {_.get(props, 'topics', []).map(item=>(
      <Tag key={item.id}><img src={tagIcon} alt='Tag Icon'/>{item.name}</Tag>
    ))
    }

    {_.get(props, 'focusAreas', []).map(item=>(
      <Tag key={item.id}><img src={focusIcon} alt='Focus Icon' />{item.label}</Tag>
    ))}
  
    
  </div>
);