import React from 'react';
import { Link } from 'react-router-dom';
import T from '../Typography';
import { Container, Row, Col } from 'react-bootstrap';
import logoBw from '../../assets/image/cto_logo-horizontal_white-2x.png';

const Footer = (props) => (
  <>
    <footer className='bg-base-dark color-white'>
      <Container>
        <Row>
          <Col xs={12} className='pt-3' >
            <div className='border-b-1 border-b-white pb-2 mx-2 mx-lg-0'>
              <Link to="/">
                <img 
                  src={logoBw} 
                  height={40}
                  alt="Mayor's Office of the Chief Technology Officer" />
              </Link>
              </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className='pt-2 px-3 px-sm-2'>
            
            <T sm className='color-white font-weight-bold py-1'>
              This is a project of the New York City Mayor's Office of the CTO
            </T>
            <T sm sansSerif>
              <Link className='color-white border-b-1 border-b-white' to="/">
                Learn more about the office and its work.
              </Link>
            </T>
          </Col>
          <Col xs={12} sm={6} className='text-left text-sm-right pb-3 pt-2 px-3 px-sm-2'>
            <T xs className='py-1'>&copy; 2020 The Mayor's Office of the Chief Technology Officer</T>
            <T xs>The City of New York</T>

            <T xs className='pt-2'>
              <Link className='color-white border-b-1 border-b-white' 
                href="https://www1.nyc.gov/home/privacy-policy.page">Privacy Policy</Link>
            </T>
            <T xs className='pt-1'>
              <Link className='color-white border-b-1 border-b-white' 
                href="https://www1.nyc.gov/home/terms-of-use.page">Terms of Use</Link>
            </T>
          </Col>
        </Row>
      </Container>
    </footer>
  </>
);

export default Footer;