import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { Page, Footer, Header, 
    HTMLContent, Body, Slogan, Attribution,
    T, Hero, List, ProjectCard, AnimatedTitle, Feedback,
} from '../components';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import projectBG from '../assets/image/project-bg.jpg';
import { Container, Row, Col } from 'react-bootstrap';


export default (props) => {
  
  const options = [ ... _.get(props, 'categories', []).map(i => ({ value: i.slug, label: i.name })),
                      ... _.get(props, 'topics', []).map(i => ({ value: i.name, label: i.name }))
                  ];

  return (
  
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header page="projects"  />
      <Hero bg={projectBG} mode='blue'
        altText="A long-exposure photo of a crowded Grand Central Terminal." 
        className='h-page border-b-5 border-b-pop-1'> {/* bb-5 bb-pop-1 */}
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                <AnimatedTitle title="Explore our [work|delivery|resources|challenges|playbooks|reports]" />
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='bg-white pb-3'>
        <Container className=''>
          <Row className='ml-lg-n2'>
            <Col xs={12} className='d-flex py-3 px-2 pl-0'>
              <Link to='/projects' className='mr-2 border-3 brad-round py-2 px-3 border-pop-2 hover-base-dark-border bg-pop-2 color-base-dark t-link font-weight-bold'>Highlights</Link>
              <Link to='/projects-all' className='cursor-default brad-round border-3 py-2 px-3 border-base-dark color-base-dark t-link font-weight-bold'>View All</Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className='d-flex flex-column flex-md-row py-3 pl-0 align-items-center'>
              <T md className='font-weight-bold pb-0'>Search our projects</T>
              <Select
                className='filter-select'
                placeholder={_.get(props, 'filterParent[0].name', '')}
                onChange={props.handleFilterChange}
                value={
                  props.categoryFilters &&  
                  options.filter(item => props.categoryFilters.includes(item.value))
                }
                options={ 
                    [ ... _.get(props, 'categories', []).map(i => ({ value: i.slug, label: i.name })),
                      ... _.get(props, 'topics', []).map(i => ({ value: i.name, label: i.name }))
                  ]
                }
                styles={
                  {
                    control: (provided, state) => ({
                      ...provided, borderColor: "#042D36", borderWidth: 2
                    })
                  }
                }
                isMulti
              />
            </Col>
          </Row>
        </Container>
      </Body>
      <Body className='mt-1 mt-lg-0 pt-2 pt-lg-4 pb-7'>
        <Container className='p-0'>
          <Row className='ml-lg-n2'>
              {
                _.get(props, 'caseStudies', [])
                  .map(project => (
                    <Col xs={6} sm={6} md={3} key={project.id}>
                      <ProjectCard 
                        {...project} 
                        focusAreas={
                          props.focusAreas.filter(
                            focus => {
                                return project.categories.map(i => i.id).includes(focus.id)
                            }
                          )
                        }
                      />
                    </Col>
                  )
                ) 
              }
            
          </Row>
        </Container>
        
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)};