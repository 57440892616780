import React from 'react';
import {Link} from 'react-router-dom';
import logoBw from '../../assets/image/cto_logo-horizontal_white-2x.png';

import { Container, Row, Col } from 'react-bootstrap';


const Footer = (props) => (
  <footer className='bg-base-dark pt-2 pb-2 pb-lg-0 pt-lg-4 color-white w-100'>
    <Container>
      <Row>
        <Col md={5} xs={12} className='p-2 p-lg-0 is-sansserif'>
          <img src={logoBw} width="100" alt="NYC CTO Logo" />
          <div className='is-sansserif font-1 py-3'>
          &copy; 2021 The City of New York 
          </div>
          <ul className='list-unstyled pl-0 font-1 pb-0 pb-lg-4'>
            <li>
              <Link to="accessibility-statement"  className="text-decoration-underline">CTO Accessibility Statement</Link>
            </li>
            <li>
              <a href="https://www1.nyc.gov/home/privacy-policy.page" target="_blank" rel="noopener noreferrer"  className="text-decoration-underline">NYC Privacy Policy</a>
            </li>
            <li>
            <a href="https://www1.nyc.gov/home/terms-of-use.page" target="_blank" rel="noopener noreferrer" className="text-decoration-underline">NYC Terms of Use</a>
            </li>
          </ul>
        </Col>
        <Col md={7} xs={12} >
          <div className='d-flex font-1 is-sansserif justify-content-md-end justify-content-start'>
            <div className='pr-3 pr-lg-5'>
              <ul className='list-unstyled p-0 m-0'>
                <li><Link to="/about" className="text-decoration-underline">About</Link></li>
                <li><Link to="/team" className="text-decoration-underline">Team</Link></li>
              </ul>
            </div>
            <div className='pr-3 pr-lg-5'>
              <ul className='list-unstyled p-0 m-0'>
                <li><Link to="/collaboration"  className="text-decoration-underline">Collaboration</Link></li>
                <li><Link to="/projects" className="text-decoration-underline">Projects</Link></li>
                {/* <li><Link to="/work-archive">All Works</Link></li> */}
              </ul>
            </div>
            <div className='pr-3 pr-lg-5'>
              <ul className='list-unstyled p-0 m-0'>
                <li><Link to="/news" className="text-decoration-underline">News</Link></li>
                <li><Link to="/press" className="text-decoration-underline">Press &amp; Media</Link></li>
                <li><Link to="/contact" className="text-decoration-underline">Contact</Link></li>
                <li><Link to="/jobs" className="text-decoration-underline">Jobs</Link></li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;