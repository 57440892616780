import React from 'react';

export default (props) => (
  <>
  </>
)

/*
<div 
    id="feedback-widget" 
    lang="en" 
    pagetitle="NYC CTO Website" 
    endpoint="3c352mkrqp1o31">
  </div>*/