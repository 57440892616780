import React from 'react';
import _ from 'lodash';
import Typical from 'react-typical';

const REGULAR_EXPRESSION=/\[(.*?)\]/ig;
const ANIMATE_TOKEN = "<ANIMATE>";
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const AnimatedTitle = (props) => {
  let title = _.get(props, 'title', '') || '';
  
  let toBeAnimated = title.match(REGULAR_EXPRESSION);
  _.forEach(toBeAnimated, (item, index) => {
    title = title.replace(item, `||${ANIMATE_TOKEN}||`); // Setting the stage
    toBeAnimated[index] = _.flattenDeep(item.substring(1, item.length-1).split("|").map(i=>[i,1000]));
  });

  const titleArr = title.split("||");

  const flattenedTitle = titleArr.map(titleItem =>  {
    if (toBeAnimated && titleItem === ANIMATE_TOKEN) {
      const forListing = toBeAnimated[0].filter(i => i !== 1000);
      const lastItem = forListing[forListing.length - 1];
      const allItems = forListing.slice(0, -1);
      return allItems.join(", ") + `, and ${lastItem}`;
    } else {
      return titleItem
    }
  }).join(" ").replace(/<br\/>/g, "");

  let animateCounter = 0;
  return (
    <>
      <span className="sr-only">{flattenedTitle}</span>
      <div aria-hidden="true" title={flattenedTitle}>
      { 
        titleArr.map(titleItem => {
          if (toBeAnimated && titleItem === ANIMATE_TOKEN) {
            
            return <React.Fragment key={`key-${titleItem}`}>
              { isIE11 ? toBeAnimated[0][0] : <Typical
                steps={toBeAnimated[animateCounter++]}
                loop={Infinity}
                wrapper="span"
                className="color-pop-1"
                />
              }
              <br/>
            </React.Fragment>;
          } else {
            return <span tabIndex="-1" aria-hidden={true} key={`key-${titleItem}`} dangerouslySetInnerHTML={{__html: titleItem}}></span>;
          }
        })
      }
      </div>
    </>
  )
};

export default AnimatedTitle;