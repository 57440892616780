import React from 'react';
import { StickyContainer } from 'react-sticky';
import {Page, Slogan, Footer, Header, Hero , T, Body} from '../components';
import { Container, Row, Col } from 'react-bootstrap';
import pizzaRat from '../assets/image/pizza-rat.gif';
import { Link } from 'react-router-dom';

class PageNotFound extends React.Component {
  
  render = () => (
    <Page page="toolkit" pageTitle="We can't find your page!">
    <StickyContainer>
      <Header />
      <Hero className='bg-gray-medium'>
        <Container>
          <Row className='bg-gray-medium'>
            <Col className='px-md-0'>
              <T statement isMonospace h1 className='color-pop-1 pb-2'>
                Error 404.
              </T>
              <T statement  isMonospace h1 className='color-pop-2 pb-3'>
                Something isn't where we left it
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pt-6 pb-7 px-2'>
        <Container>
          <Row>
            <Col  xs={12} md={7} className='px-md-0'>
              <img src={pizzaRat} className='img-500' alt="animation of 'pizza rat' carrying pizza down stairs" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={7} className='px-md-0 pt-3'>
              <p>This is probably on us. We recommend you start on our <Link to='/' className='color-black text-decoration-underline'>homepage</Link>, learn more <Link to='/about' className='color-black text-decoration-underline'>about us</Link>, or look at some of <Link to='/projects' className='color-black text-decoration-underline'>our work</Link>.</p>
              <p>If you want to get in touch with somebody at the office of the CTO, please <Link to='/contact' className='color-black text-decoration-underline'>contact us</Link>.</p>
              <p>If you wish to file a complaint, submit a service request, or ask a question about a city service, call <a href="tel:311" className='color-black text-decoration-underline'>311</a> or visit <a href='//nyc.gov/311' className='color-black text-decoration-underline'>nyc.gov/311</a>.</p>
            </Col>
          </Row>
        </Container>
      </Body>
      <Slogan />
      <Footer />
    </StickyContainer>
  </Page>
  );

}

export default PageNotFound;