import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToSomewhere() {

  const { pathname, search, hash } = useLocation();
  
  const scrollToTop = () => {
    window.scrollTo(0, 1);
    document.body.scrollTop = 0;  
  }

  const scrollToLocation = (n = 0) => {

    if (hash !== '') {
      // look for the element, it may not have been fetched yet.
      let el = document.getElementById(hash.slice(1));
      if (el) {
        // If we found the element, scroll to it, taking the header height into consideration
        const scrollTarget = el.getBoundingClientRect().top + window.pageYOffset;
        const headerSize = 100
        window.scrollTo({ top: scrollTarget - headerSize, behavior: 'smooth' })
      } else if (n < 15) {
        // else retry up to a limit - just in case we're fetching an element over a network and it isn't there in time.
        setTimeout(() => { scrollToLocation(n + 1) }, 100)
      }
    } else if (!pathname.startsWith("/projects-all") ) {
      scrollToTop();
    }
  }

  useEffect(() => {
    scrollToLocation();
  }, [pathname, search]);

  return null;
}