import _ from 'lodash';

const defaultState = {
  activeJobPosting: null,
};




export default (state = defaultState, action) => {

  switch(action.type) {
    case "WORDPRESS_RETRIEVE_JOB_POSTING_SUCCESS": {

      const response = _.get(action, 'payload.data[0]');
      return {
        ...state,
        activeJobPosting: {
          id: response.id, 
          title: _.get(response, 'title.rendered'),
          content: _.get(response, 'content.rendered'),
          excerpt: _.get(response, 'excerpt.rendered'),
          embedFormId: _.get(response, 'acf.airtable_form_id'),
          imageAttribution: _.get(response, 'acf.image_attribution'),
        }
      };
    };

    default: {
      return state;
    }
  }
};