import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, Body, HTMLContent, Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';

import newsBG from '../assets/image/bg-news.jpg';

const PageTemplate = (props) => {

  return (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header 
      {...props} />
      <Hero bg={newsBG} 
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace className='color-base-dark'>
                Press release
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pb-7 pt-4'>
        <Container className='p-2 p-lg-0'>
          <Row>
             <Col xs={12} className='px-0'>
              <HTMLContent className='content' content={_.get(props.activePressRelease, 'content')}>
              </HTMLContent>
             </Col>
          </Row>
        </Container>
        
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)};

export default PageTemplate;