import React from 'react';
import _ from 'lodash';
import { retrieveNewsArchive, retrieveNewsSources } from '../actions/wp';
import { connect } from 'react-redux';
import { NewsArchiveTemplate } from '../templates';

class NewsArchiveContainer extends React.Component {
  
  componentDidMount = () => {
    this.props.retrieveNewsArchive(new Date(_.get(this.props, 'year')));
    this.props.retrieveNewsSources();
  }
  
  componentDidUpdate = (prevProps) => {

    if (prevProps.year !== _.get(this.props, 'year')) {
      this.props.retrieveNewsArchive(new Date(_.get(this.props, 'year')));
    }
    
  }

  render = () => (
    <NewsArchiveTemplate 
      {...this.props}
    />
  )
}

const mapStateToProps = (state) => ({
  newsArchive: _.get(state.news, 'newsArchive'),
  newsSources: _.get(state.news, 'newsSources'),
});

export default connect(mapStateToProps, {
  retrieveNewsArchive,
  retrieveNewsSources
})(NewsArchiveContainer);