import React from 'react';
import MarkdownContent from './content/MarkdownContent';
import { Container, Row, Col } from 'react-bootstrap';

const AttributionArea = (props) => (
  <div className='w-100 bg-base-dark pt-3 px-lg-3 px-2 px-md-2' role="region">
    <Container>
      <Row>
        <Col className="pb-lg-4 pb-2 px-0 border-b-gray-medium border-b-1">
          <MarkdownContent 
            className='color-white is-monospace attribution-area'
            content={props.attribution} 
          />
        </Col>
        </Row>
      </Container>
  </div>
);

export default AttributionArea;