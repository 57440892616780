import React, { useState } from 'react';
import Logo from '../Logo';
import { Sticky } from 'react-sticky';
import ToolkitSidebar from '../toolkit/ToolkitSidebar';
import chevronDown from '../../assets/image/chevron-down.svg';
import closeMenu from '../../assets/image/x-circle.svg';
import { Container, Row, Col } from 'react-bootstrap';

const Header = ({page, ...props}) => {
  
  const [isMenuVisible, setMenuVisibility] = useState(false);

  return (
    <Sticky className={`sticky-container z-top toolkit-header`}>
      {({ style, distanceFromTop }) =>
        (
        <div  style={{ ...style}} className={`z-top ${isMenuVisible ? 'menu-visible' : 'menu-hide'} ${parseInt(distanceFromTop) < -30 ? 'logo-small-ver' : ''}`}>
          <header className="header-container d-flex flex-column p-lg-0 px-2 px-lg-0 bg-white shadow-sm">
            <Container fluid className={`toolkit-title  p-0 ${!isMenuVisible ? 'anim-hide' : ''} ${parseInt(distanceFromTop) < -30 ? 'hide' : ''} 
               text-uppercase full-header`}>
              <Row>
                <Col lg={11} xl={10} className={`d-flex p-0 ustify-content-between align-items-center is-monospace ${isMenuVisible ? '': 'border-b-pop-1 border-b-1 border-b-lg-0 pl-0 pl-lg-3'}`}>
                  <div className={`toolkit-title ${parseInt(distanceFromTop) < -30 ? 'hide' : ''} w-100 text-uppercase full-header d-flex justify-content-between align-items-center is-monospace`}>
                    <Logo scrolled={distanceFromTop < -30} className='d-none d-lg-block'/>
                    <div className='d-flex justify-content-between pt-3 pb-2 py-lg-0 align-items-center w-100 w-lg-auto color-xs-pop-1 colo-base-dark'> 
                      <span><strong>Toolkit:</strong> {props.title}</span>
                      {
                        isMenuVisible && 
                        <button onClick={() => { setMenuVisibility(false) }}>
                          <img src={closeMenu} alt="Close Menu"/>
                        </button>
                      }
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

            <div className={`header-area toolkit-nav full-header d-flex d-lg-none justify-content-between align-items-center is-monospace`}>
              <div className={'toc-area'}>
                <ToolkitSidebar 
                  content={props.content} 
                  jumpTo={props.jumpTo}
                  className='pb-0'
                  activeSection={props.activeSection}
                  activeSubsection={props.activeSubsection}
                  title={props.title}
                  onClick={() => { setMenuVisibility(false); }}
                  isMenuVisible={isMenuVisible}
                />
                {
                  isMenuVisible &&
                  <div className='py-2 bt-1 border-t-1 border-t-gray-medium logo-area-container'>
                    <Logo className='d-block d-lg-none' scrolled={true}/> 
                  </div>
                }
              </div>
              {
                !isMenuVisible &&
                (<button className='d-block d-lg-none p-0' onClick={() => { setMenuVisibility(!isMenuVisible) }}>
                  <img src={chevronDown} alt="mobile menu" />
                </button>)
              }
            </div>
          </header>
        </div>
    )}
    </Sticky>
  )
};
export default Header;