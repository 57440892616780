import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, Body, NewsItem, Content,Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const PageTemplate = (props) => {
  const currentYear = new Date().getFullYear();
  return (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header 
      {...props} />
      <Hero bg={"https://nyctechstage.wpengine.com/wp-content/uploads/2020/04/180416_DailyLife_BK2_8862_Unbranded.jpg"} 
        mode='blue' 
        className='h-page border-b-5 border-b-pop-1'>
        <Container>
          <Row>
            <Col xs={12} className='mx-lg-n2'>
              <T statement isMonospace>
                News Archive
              </T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pb-7 pt-4'>
        <Container className='p-2 p-lg-0'>
          <Row>
            <Col xs={12} className='p-0'>
              <Content>
              {
                _.get(props, 'newsArchive', []).map(
                  (item) => {
                    const newsSource = _.get(props.newsSources.filter(i=>i.id===item.newsSource), '[0]');
                    return (
                    <NewsItem 
                      {...item} 
                      srcLogo ={_.get(newsSource, 'logo')}
                      srcTitle ={_.get(newsSource, 'title')}
                    />
                  )}
                )
              }
              <div className='pt-3'>
                {
                  _.times(currentYear - 2016, item =>  (
                    <Link to={`/news-archive/${currentYear-item}`} className='mr-2 t-link font-weight-bold color-base-dark animated-underline' aria-label={`Go to News in ${currentYear - item}`}>{currentYear - item}</Link>
                  ))
                }
              </div>
              </Content>
            </Col>
          </Row>
        </Container>
        
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
)};

export default PageTemplate;