import _ from 'lodash';
const defaultState = {
  banner: null
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case "WORDPRESS_RETRIEVE_BANNER_SUCCESS": {
      return ({
        ...state,
        banner: _.get(action, 'payload.data.notification')
      })
    }

    default: 
      return state;
  }
}