import React from 'react';

export default (props) => (
  <div style={{
      width: props.size || 50, 
      borderRadius: props.size || 50, 
      ...props.style
    }} className={props.className}>
    <div className='d-block bg-cover w-100'
      title={props.title}
      role="img"
      style={{
        paddingBottom: "100%",
        backgroundImage: `url(${props.image})`,
        borderRadius: props.size || 50,
        ...props.photoStyle
      }}
    />
  </div>
)