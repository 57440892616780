import React from 'react';
import _ from 'lodash';
import T from './Typography';
import { Link } from 'react-router-dom';  

export default (props) => (
<div className={`bg-white d-inline-flex align-items-center w-100 p-3 ${props.className||''}`} key={props.id}>
    <div className='d-flex flex-column  pr-md-3 flex-occupy'>
      <T className='t-captionhead mb-2 text-uppercase'>{_.get(props, 'releaseDate')}</T>
      <p  className='color-base-dark pb-0'>
        {
          _.get(props, 'redirectLink', "") === "" 
            ? <Link to={`/press-release/${props.slug}`} dangerouslySetInnerHTML={{__html: _.get(props, 'title')}}></Link>
            : <a href={props.redirectLink} dangerouslySetInnerHTML={{__html: _.get(props, 'title')}}></a>
        }
      </p>
    </div>
    
    {/* <div className='d-flex'>
      <div className='brad-round py-2 px-3 border-2 border-base-dark'>
        <T className='t-captionhead color-base-dark'>
          {
            _.get(props, 'redirectLink', "") === "" 
              ? <Link to={`/press-release/${props.slug}`}>Press Release</Link>
              : <a href={props.redirectLink}>Press Release</a>
          }
        </T>
      </div>
      <div className='brad-round py-2 px-3 border-2 border-base-dark'>
        <T className='t-captionhead color-base-dark'>
        {
          _.get(props, 'pressKit') 
            ? <a href={props.pressKit}>Social Media Downloads</a>
            : null                 
        }
        </T>
      </div>
    </div> */}
  </div>
);
