import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { 
  Page, Slogan, Footer, Header, Attribution,
  T, Hero, AnimatedTitle, Body, HTMLContent, Feedback
} from '../components';
import { Container, Row, Col } from 'react-bootstrap';


const PageTemplate = (props) => (
  <Page pageTitle={_.get(props, 'title')}>
    <StickyContainer>
      <Header {...props} />
      <main id="maincontent">
        <Hero
          className='h-page'
        >
          <Container>
            <Row>
              <Col xs={12} className='mx-lg-n2'>
                <T statement isMonospace>
                  <AnimatedTitle title={_.get(props, 'title')} />
                </T>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Body className='pt-4'>
          <HTMLContent className='w-100' content={_.get(props, 'content')} />
        </Body>
        <Slogan />
        { props.attribution && 
          <Attribution attribution={props.attribution} />
        }
        <Feedback />
        <Footer />
      </main>
    </StickyContainer>
  </Page>
);

export default PageTemplate;