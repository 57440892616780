import moment from 'moment';
/**
 * 
 * wp.js
 * Wordpress REST API Operations
 *
 */

/**
 * 
 * @param {String} slug 
 */
export const retrievePage = (slug) => ({
  type: "WORDPRESS_RETRIEVE_PAGE",
  payload: {
    request: {
      method: 'GET', 
      url: `/pages?slug=${slug}&_embed`,
    }
  }
})

export const retrieveNewsCategories = () => ({
  type: "WORDPRESS_RETRIEVE_NEWS_CATEGORIES",
  payload: {
    request: {
      method: 'GET', 
      url: `/news_categories?_embed`,
    }
  }
});


export const retrievePressKits = () => ({
  type: "WORDPRESS_RETRIEVE_PRESS_KITS", 
  payload: {
    request: {
      method: 'GET', 
      url: `/press_story?_embed&per_page=100`,
    }
  }
});

export const retrieveToolkit = (slug) => ({
  type: "WORDPRESS_RETRIEVE_TOOLKIT",
  payload: {
    request: {
      method: 'GET', 
      url: `/toolkits?slug=${slug}&_embed`
    }
  }
})

export const setPressRelease = (activePressRelease) => ({
  type: "WORDPRESS_SET_PRESS_RELEASE",
  payload: {
    data: activePressRelease,
  }
})

export const retrievePressRelease = (slug) => ({
  type: "WORDPRESS_RETRIEVE_PRESS_RELEASE",
  payload: {
    request: {
      method: 'GET',
      url: `/press_story`,
      params: {
        _embed: true, 
        slug
      }
    }
  }
})

export const retrieveNewsArchive = (date = new Date()) => {
  
  var m = moment(date);
  m.add(1, 'day');  
  var after = m.format('YYYY-01-01T00:00:00');
  m.add(1, 'year');
  var before = m.format('YYYY-01-01T00:00:00');

  return {
    type: "WORDPRESS_RETRIEVE_NEWS_ARCHIVE", 
    payload: {
      request: {
        method: 'GET', 
        url: `/news?_embed&before=${before}&after=${after}&per_page=100`
      }
    }
  };
}


export const retrieveTeamMembers = () => ({
  type: "WORDPRESS_RETRIEVE_TEAM",
  payload: {
    request: {
      method: 'GET',
      url: '/team_members?_embed'
    }
  }
});

export const retrieveTeamBio = (slug) => ({
  type: "WORDPRESS_RETRIEVE_TEAM_BIO",
  payload: {
    request: {
      method: 'GET',
      url: `/team_members`,
      params: {
        _embed: true, 
        slug
      }
    }
  }
})

export const retrieveCaseStudies = () => ({
  type: "WORDPRESS_RETRIEVE_CASE_STUDIES",
  payload: {
    request: {
      method: 'GET', 
      url: `/case_studies?_embed&per_page=100`,
    }
  }
});

export const retrieveCaseStudyCategories = () => ({
  type: "WORDPRESS_RETRIEVE_CASE_STUDY_CATEGORIES", 
  payload: {
    request: {
      method: 'GET', 
      url: `/case_study_categories?_embed&per_page=100`,
    }
  }
})

export const retrieveCaseStudyTopics = () => ({
  type: "WORDPRESS_RETRIEVE_CASE_STUDY_TOPICS", 
  payload: {
    request: {
      method: 'GET', 
      url: `/case_study_topics?_embed&per_page=100`,
    }
  }
})

export const retrieveCaseStudy = (slug) => ({
  type: "WORDPRESS_RETRIEVE_CASE_STUDY",
  payload: {
    request: {
      method: 'GET', 
      url: `/case_studies?_embed&slug=${slug}&x=${new Date()}`,
    }
  }
});

export const retrieveJobPosting = (slug) => ({
  type: "WORDPRESS_RETRIEVE_JOB_POSTING", 
  payload: {
    request: {
      method: "GET",
      url: `/job_posting?_embed&slug=${slug}`
    }
  }
});

export const retrieveNewsSources = (slug) => ({
  type: "WORDPRESS_RETRIEVE_NEWS_SOURCES", 
  payload: {
    request: {
      method: "GET",
      url: `/news_source?_embed&per_page=100`
    }
  }
});

export const retrieveBannerNotification = () => ({
  type: "WORDPRESS_RETRIEVE_BANNER", 
  payload: {
    request: {
      method: "GET", 
      url: `/active_notification`
    }
  }
})