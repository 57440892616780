import React from 'react';
import Helmet from 'react-helmet';

export default (props) => (
  <React.Fragment>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Mayor's Office of the Chief Technology Officer</title>
        <link rel="canonical" href={props.pageLink || "https://www1.nyc.gov/cto" } />
        <meta name="description" content={props.description || " Mayor's Office of the Chief Technology Officer "} />
        <script src="https://d2ttz3as5y3dj0.cloudfront.net/feedback-module.min.js"></script>
    </Helmet>
    <div className={`page`} data-page={props.page || "default"}>
      {props.children}
    </div>
  </React.Fragment>
)