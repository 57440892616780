import React from 'react';
import { Link } from 'react-router-dom'; 

const JobDescription = (props) => (
  <section className='job-description'>
    <div dangerouslySetInnerHTML={{__html: props.content}} />
    <Link to="?showForm=true" className='mt-3 d-inline-block border-3 brad-round py-2 px-3 border-pop-2 hover-base-dark-border bg-pop-2 color-base-dark t-link font-weight-bold'>Apply Now</Link>
  </section>
)

export default JobDescription;