
import _ from 'lodash';

export const getActiveSection = (querySelector) => {
  var sections = document.querySelectorAll(querySelector);
  let currentSection = null;
  let currBBox = null;

  if (sections) {
    _.forEach(sections, (section) => {
      const bbox = section.getBoundingClientRect();
      
      if(!currentSection) {
        currentSection = section;
        currBBox = bbox;
      }
      if(bbox.top - 75 < 0 
          && bbox.top - 75 > currBBox.top 
          && section !== currentSection) {
        currentSection = section;
      }
    });
  }

  return currentSection;
}


export default {
  getActiveSection
};