import React from 'react';
import HTMLContent from './HTMLContent';
import MarkdownContent from './MarkdownContent';

const Content = (props) => {
  
  if (props.variant === "html") {
    return <HTMLContent {...props} />
  }

  if (props.variant === "markdown") {
    return <MarkdownContent {...props} />
  }

  return <div className={`content ${props.className}`}>{props.children}</div>
}

export default Content;
