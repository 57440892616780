import React from 'react';
import _ from 'lodash';
import { StickyContainer } from 'react-sticky';
import { Page, Footer, Header, 
    HTMLContent, Body, Slogan, Attribution,
    T, Hero, CircleImage, Feedback
} from '../components';
import staffBG from '../assets/image/bg-staff.jpg';
import { Container, Row, Col } from 'react-bootstrap';

const TeamBioTemplate = (props) => (
  <Page className='bg-gray-light'>
    <StickyContainer>
      <Header  page="about"  />
      <Hero bg={staffBG} photoImage={false} variant="short" className='border-b-5 border-b-pop-1 bg-cover-light align-items-end'>
        <Container>
          <Row>
            <Col xs={12} className='p-2 p-lg-0'>
              <T statement isMonospace className='mb-2'>{_.get(props.activeTeamMember, 'name')}</T>
              <T subtitle className='text-uppercase pb-0'>{_.get(props.activeTeamMember, 'position')}</T>
            </Col>
          </Row>
        </Container>
      </Hero>
      <Body className='pt-4 pb-7'>
        <Container>
          <Row>
            <Col lg={7} xs={12} className='px-0 text-center text-md-left pb-3' tabIndex={0}>
              <CircleImage
                size={300}
                className='d-inline-block'
                image={_.get(props.activeTeamMember, 'photo')}
                title={`Headshot of ${_.get(props.activeTeamMember, 'name')}`}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={7} className='p-2 p-lg-0 pt-4' tabIndex={0}>
              <HTMLContent className='content' content={_.get(props.activeTeamMember, 'content')} />
            </Col>
          </Row>
        </Container>
      </Body>
      <Feedback />
      <Slogan />
      { props.attribution && 
        <Attribution attribution={props.attribution} />
      }
      <Footer />
    </StickyContainer>
  </Page>
);

export default TeamBioTemplate;