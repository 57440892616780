import React from 'react';
import ToolkitFooter from './ToolkitFooter';
import DefaultFooter from './DefaultFooter';

export default (props) => {

  if (props.variant === "toolkit") {
    return <ToolkitFooter {...props} />
  }

  return (<DefaultFooter {...props} />);
}