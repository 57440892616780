import React from 'react';
import { StickyContainer } from 'react-sticky';
import { Container, Row, Col } from 'react-bootstrap';
import { Page, Footer, ToolkitHeader, Content, Body } from '../components';
import Sidebar from '../components/toolkit/ToolkitSidebar';

const ToolkitTemplate = (props) => (
    <Page page="toolkit">
      <StickyContainer>
        <ToolkitHeader variant='toolkit' {...props} />
        <Body className='pt-0 pr-2'>
          <Container fluid className='px-0'>
            <Row>
              <Col lg={4} xl={3} className='d-none d-lg-block sticky-header'>
                <Sidebar
                  content={props.content}
                  jumpTo={props.jumpTo}
                  activeSection={props.activeSection}
                  activeSubsection={props.activeSubsection}
                  title={props.title}
                />
              </Col>
              <Col lg={{ span: 7, offset: 1 }} xl={{ span: 6, offset: 1 }} xs={12} className='pb-4 pb-lg-6'>
                <Content variant="html" className='main-content toolkit-content  sr-only-focusable' content={props.content}/>
              </Col>
            </Row>
          </Container>
        </Body>
        <Footer  {...props} variant={'toolkit'} />
      </StickyContainer>
    </Page>
  );

export default ToolkitTemplate;