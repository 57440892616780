import _ from 'lodash';
const defaultState = {
  activeToolkit: null, 
  toolkits: [],
};

export default (state = defaultState, action) => {
  switch(action.type) {
    case "WORDPRESS_RETRIEVE_ALL_TOOLKITS_SUCCESS": {
      return ({
        ...state,
        teamMembers: _.get(action, 'payload.data').map(item=>({
          id: _.get(item, 'id'),
          title: _.get(item, 'title.rendered'),
          content: _.get(item, 'content.rendered'),
          photo: _.get(item, '_embedded.wp:featuredmedia[0].source_url'),
          slug: _.get(item, 'slug')
        }))
      })
    }

    case "WORDPRESS_RETRIEVE_TOOLKIT_SUCCESS": {
      const response = _.get(action, 'payload.data[0]');
      return ({
        ...state, 
        activeToolkit: {
          id: _.get(response, 'id'),
          title: _.get(response, 'title.rendered'),
          content: _.get(response, 'content.rendered'),
          photo: _.get(response, '_embedded.wp:featuredmedia[0].source_url'),
          slug: _.get(response, 'slug')
        }
      })
    }

    default: return state;
  }
};