import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownContent = (props) => (
  <ReactMarkdown 
    {...props}
    source={props.content} 
    className={`content md-content ${props.className}`}
  />
);

export default MarkdownContent;