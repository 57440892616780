import React from 'react';

const FontFamily = {
  SERIF : "is-serif", 
  SANS_SERIF: "is-sans-serif",
  MONOSPACE: "is-monospace"
};

const Div = ({ children, ...props}) => <div {...props}>{children}</div>
const Span =  ({ children , ...props}) => <span {...props}>{children}</span>;
const P = ({children, ...props}) => <p {...props}>{children}</p>;
const H1 = ({children, ...props}) => <h1 {...props}>{children}</h1>;
const H2 = ({children, ...props}) => <h2 {...props}>{children}</h2>;
const H3 = ({children, ...props}) => <h3 {...props}>{children}</h3>;
const H4 = ({children, ...props}) => <h4 {...props}>{children}</h4>;
const H5 = ({children, ...props}) => <h5 {...props}>{children}</h5>;

const getDom = (props) => {
  if (props.span) {
    return Span;
  }
  else if (props.p) {
    return P;
  } else if (props.h1) {
    return H1;
  } else if (props.h2) {
    return H2;
  } else if (props.h3) {
    return H3;
  } else if (props.h4) {
    return H4;
  } else if (props.h5) {
    return H5;
  }

  return P;
}

const Typography = (props) => {
  let fontFamily = FontFamily.SANS_SERIF; 
  
  if (props.isSerif) {
    fontFamily = FontFamily.SERIF;
  } else if (props.isMonospace) {
    fontFamily = FontFamily.MONOSPACE;
  }
  const Element = getDom(props);

  if (props.sm) {
    return (<Element className={`font-1 ${fontFamily} ${props.className||''}`}>{props.children}</Element>);
  }
  
  if (props.xs) {
    return (<Element className={`font-0 ${fontFamily} ${props.className||''}`}>{props.children}</Element>);
  }

  if (props.md) {
    return (<Element className={`font-2 ${fontFamily} ${props.className||''}`}>{props.children}</Element>);
  }

  if (props.subtitle) {
    return (<h2 className={`t-subtitle ${fontFamily}  ${props.className||''}`}>{props.children}</h2>);
  }

  if (props.statement || props.title) { 
    return (
      props.html ? 
      <h1 className={`t-statement ${props.className||''}`} dangerouslySetInnerHTML={{__html: props.html}}></h1>
      : <h1 className={`t-statement ${props.className||''}`}>{props.children}</h1>
    )
  }


  return (<span className={props.className}>{props.children}</span>);
};

export default Typography;