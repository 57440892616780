import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { retrieveBannerNotification } from'../actions/wp';

class NotificationBanner extends React.Component {

  componentDidMount = () => {
    if (!this.props.banner) {
      this.props.retrieveBannerNotification();
    }
  }

  render = () => (
    <div className={`${_.get(this.props.banner, 'notification_text') && 'py-1'} text-center w-100 notification-banner is-sansserif hideable font-1 ${this.props.hide ? 'hide' : ''}`} 

      style={{
        backgroundColor: _.get(this.props.banner, 'background_color'),
        color: _.get(this.props.banner, 'font_color')
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  banner: _.get(state, 'banner.banner'),
});

export default connect(mapStateToProps, { 
  retrieveBannerNotification
})(NotificationBanner);