import React from 'react';
import _ from 'lodash';
import numeral from 'numeral';
import {
  retrieveCaseStudy,
  retrieveCaseStudyCategories,
} from '../actions/wp';
import { connect } from 'react-redux';
import {Error404Template , ProjectTemplate} from '../templates';

class CaseStudyContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: [0.50, 0.99],
    }

  }

  loadPage = () => {
    this.props.retrieveCaseStudy(this.props.slug);
    this.props.retrieveCaseStudyCategories();
  }


  componentDidMount = () => {
    
    
    window.addEventListener('scroll', this.onScroll, false);
    document.body.addEventListener('touchmove', this.onScroll, false);
    document.body.addEventListener("gesturechange", this.onScroll, false);

    window.addEventListener("hashchange", this.loadPage, false);
    this.loadPage();
  }
  

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll, false);
    window.removeEventListener("hashchange", this.loadPage, false);
    document.body.removeEventListener('touchmove', this.onScroll, false);
    document.body.removeEventListener("gesturechange", this.onScroll, false);
  }

  onScroll = () => {
    var targetItems = document.querySelectorAll('.t-stats');
    _.each(targetItems, item => {
      const bounding = item.getBoundingClientRect();

      if (
        bounding.top >= 0 &&
        // bounding.left >= 0 &&
        // bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        !item.classList.contains("visible")
      ) {
        this.handleObservation(item);
      }
    })
  }


  handleObservation = (entry) => {

    if (!entry.classList.contains('visible')) {
        let format = null;
        
        entry.classList.add("visible");
        const targetValue = parseFloat(entry.getAttribute("data-value"));
        const type = entry.getAttribute("data-type");

        switch (type) {
          case 'percentage': format = '0.'; break;
          case 'plain': format = ','; break;
          case 'amount': format = '0.0a'; break;
          default: format = ','; break;
        }
        let value = parseFloat(entry.innerHTML);
        let interval = setInterval(() => {
          if (type === "percentage") {
            if (targetValue % 1 === 0) {  
              value = Math.round((value + 1) * 100) / 100;

            } else {
              value = Math.round((value + 0.5) * 100) / 100;
            }
            
          } else if (targetValue - value < 100) {
            value++;
          } else if (targetValue - value < 10000) {
            value += 100;
          } else if (targetValue - value < 100000) {
            value += 1000;
          } else {
            value += 100000;
          }

          if (value > targetValue) {
            clearInterval(interval);
            let val = entry.innerHTML;
            if (parseFloat(val) === parseInt(val) && type !== "plain") {
              entry.innerHTML = numeral(val).format("0.a");
            } else {
              entry.innerHTML = val;
            }
            return false;
          }

          entry.innerHTML = numeral(value).format(format);

        }, 10);
      }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!_.isEqual(prevProps.slug, this.props.slug)) {
      this.props.retrieveCaseStudy(this.props.slug);
    }

  }

  getDates = () => {
    return 2020;
  }

  getLocations = () => { }

  getCategories = (slug) => {
    if (!this.props.activeCaseStudy) {
      return [];
    }

    const parent = _.find(this.props.parentCategories, { slug });
    const parentId = _.get(parent, 'id');
    const categIds = _.get(this.props, 'activeCaseStudy.categories');
    const caseStudyCategs = _.get(this.props, 'categories', []).filter(categ => categIds.includes(categ.id) && categ.parent_id === parentId);

    return caseStudyCategs;
  }

  render = () => {
    return this.props.error ? 
      <Error404Template /> : (
      <ProjectTemplate 
        {...this.props}
        {...this.state}
        dates={this.getDates()}
        locations={this.getCategories('location')}
        focusAreas={this.getCategories('focus-area')}
        formats={this.getCategories('format')}
        pageTitle={this.props.title}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  activeCaseStudy: _.get(state, 'caseStudy.activeCaseStudy'),
  content: _.get(state, 'caseStudy.activeCaseStudy.content'),
  projectStatus: _.get(state, 'caseStudy.activeCaseStudy.projectStatus'),
  title: _.get(state, 'caseStudy.activeCaseStudy.title'),
  subtitle: _.get(state, 'caseStudy.activeCaseStudy.subtitle'),
  image: _.get(state, 'caseStudy.activeCaseStudy.image'),
  imageAltText: _.get(state, 'caseStudy.activeCaseStudy.imageAltText'),
  attribution: _.get(state, 'caseStudy.activeCaseStudy.imageAttribution'),
  tags: _.get(state, 'caseStudy.activeCaseStudy.tags'),
  outputs: _.get(state, 'caseStudy.activeCaseStudy.outputs'),
  categories: _.get(state, 'caseStudy.categories'),
  parentCategories: _.get(state, 'caseStudy.parentCategories'),
  error: _.get(state, 'caseStudy.error'),
});

export default connect(mapStateToProps, {
  retrieveCaseStudy,
  retrieveCaseStudyCategories,
})(CaseStudyContainer);