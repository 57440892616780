import React from 'react';
import _ from 'lodash';
import { retrievePressRelease } from '../actions/wp';
import { connect } from 'react-redux';

import PressReleaseTemplate from '../templates/PressReleaseTemplate';

class PressReleaseContainer extends React.Component {

  componentDidMount = () => {
    this.props.retrievePressRelease(this.props.slug);
  }
  
  componentDidUpdate = (prevProps, prevState) => {

  }

  render = () => (
    <PressReleaseTemplate {...this.props} />
  )
}

const mapStateToProps = (state) => ({
  activePressRelease: _.get(state.news, 'activePressRelease')
});

export default connect(mapStateToProps, {
  retrievePressRelease
})(PressReleaseContainer);