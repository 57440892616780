import React from 'react';
import { Link } from 'react-router-dom'; 

const JobForm = (props) => props.embedFormId ? (
  <section className='job-form'>
      <Link to="?showForm=false" className="mb-3 d-inline-block border-3 brad-round py-2 px-3 border-pop-2 hover-base-dark-border bg-pop-2 color-base-dark t-link font-weight-bold">&#8592; Go back to Job Description</Link>
			<iframe className="airtable-embed airtable-dynamic-height" 
				src={`https://airtable.com/embed/${props.embedFormId}`}
				frameBorder="0"
				title="Job Listing"
				width="100%" height="533" style={{background: 'transparent', border: 'none'}}></iframe>
  </section>
) : null;
export default JobForm