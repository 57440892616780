import _ from 'lodash';
import moment from "moment";
const defaultState = {
  isLoading: false, 
  pressKits: [],
  activePressRelease: null,
  newsArchive: [],
  newsSources: [],
};

export default (state = defaultState, action) => {
  switch(action.type) {
    
    case 'WORDPRESS_RETRIEVE_PRESS_KITS_SUCCESS':{
      const responses = _.get(action, 'payload.data');
      return ({
        ...state, 
        pressKits: _.map(responses, (item) => ({
          id: item.id, 
          slug: item.slug,
          title: _.get(item, 'title.rendered'),
          excerpt: _.get(item, 'excerpt.rendered'),
          content: _.get(item, 'content.rendered'),
          pressKit: _.get(item, 'acf.social_media_downloads'), 
          pressKitContent: _.get(item, 'acf.files_contained'),
          releaseDate: moment(_.get(item, 'acf.published_date', new Date()), "DD/MM/YYYY").format("MMMM D, YYYY"),
          redirectLink: _.get(item, 'acf.redirect_url'),
        }))
      });
    };

    case 'WORDPRESS_SET_PRESS_RELEASE': 
      return ({
        ...state,
        activePressRelease: _.get(action, 'payload.data')
      });

    case 'WORDPRESS_RETRIEVE_PRESS_RELEASE': {
      return ({
        ...state, 
        isLoading: true,
        activePressRelease: null,
      });
    }

    case 'WORDPRESS_RETRIEVE_PRESS_RELEASE_SUCCESS': {
      const response = _.get(action, 'payload.data[0]');

      return ({
        ...state, 
        isLoading: false,
        activePressRelease: {
          id: response.id, 
          slug: response.slug,
          title: _.get(response, 'title.rendered'),
          excerpt: _.get(response, 'excerpt.rendered'),
          content: _.get(response, 'content.rendered'),
          pressKit: _.get(response, 'acf.social_media_downloads'), 
          pressKitContent: _.get(response, 'acf.files_contained'),
          releaseDate: _.get(response, 'acf.release_date'),
          redirectLink: _.get(response, 'acf.redirect_url'),
          imageAttribution: _.get(response, 'acf.image_attribution'),
        }
      })
    }

    case 'WORDPRESS_RETRIEVE_NEWS_ARCHIVE_SUCCESS': {
      
      const responses =  _.get(action, 'payload.data');
      return ({
        ...state, 
        newsArchive: _.map(responses, (item) => ({
          url: _.get(item, 'acf.redirect_url'),
          title: _.get(item, 'title.rendered'),
          excerpt: _.get(item, 'excerpt.rendered'),
          datePublished: moment(_.get(item, 'date', new Date()), "YYYY-MM-DDTHH:mm:ss").format("MMMM D, YYYY"),
          newsSource: _.get(item, 'acf.news_source[0].ID')
        }))
      });
    }

    case 'WORDPRESS_RETRIEVE_NEWS_SOURCES_SUCCESS':
      const responses =  _.get(action, 'payload.data');

      return ({
        ...state,
        newsSources: _.map(responses, item => ({
          id:  _.get(item, 'id'),
          title:  _.get(item, 'title.rendered'),
          logo:  _.get(item, '_embedded.wp:featuredmedia[0].source_url'),
        })),
      })

    default: 
      return state
  }
};