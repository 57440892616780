import React from 'react';
import _ from 'lodash';
import { retrieveCaseStudies, retrieveCaseStudyCategories, retrieveCaseStudyTopics } from '../actions/wp';
import { connect } from 'react-redux';

import ProjectArchiveTemplate from '../templates/ProjectArchiveTemplate';

class ProjectArchiveContainer extends React.Component {

  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.props.retrieveCaseStudies();
    this.props.retrieveCaseStudyCategories();
    this.props.retrieveCaseStudyTopics();
  }
  
  componentDidUpdate = (prevProps, prevState) => {

  }

  handleFilterChange =  ( selectedOptions ) => {
    const categoryFilters = selectedOptions ? selectedOptions.map(opt => opt.value) : [];
    this.props.history.push(`/projects-all/${categoryFilters.join('|')}`)
  }

  getCaseStudies = () => {
    // Filters the case studies
    let caseStudies = this.props.caseStudies;

    if (this.props.query && this.props.query.length > 0) {
      const categoryFilters = this.props.query.split("|")
      caseStudies = caseStudies.filter(caseItem => {
        const categoryMatch = _.intersection(caseItem.categories.map((caseItem) => caseItem.slug), categoryFilters);
        
        const topicMatch = _.intersection(_.get(caseItem, "topics", []).map((topic) => topic.name), categoryFilters);

        return _.get(categoryMatch, 'length', 0) > 0 
              || _.get(topicMatch, 'length', 0) > 0;
      });
    }

    if (_.get(this.state, "format", null) !== null) {
      caseStudies = caseStudies.filter(caseItem => {
        const matches = _.filter(caseItem.categories, ['id', _.get(this.state, "format.value")]);
        return matches.length > 0;
      });
    }

    if (_.get(this.state, "topic", null) !== null) {
      caseStudies = caseStudies.filter(caseItem => {
        const matches = _.filter(caseItem.categories, ['id', _.get(this.state, "topic.value")]);
        return matches.length > 0;
      });
    }

    return caseStudies;
  }
  
  buildCategories = () => {
    
    const categories = this.props.parentCategories.filter(
      parentCategs =>  _.get(parentCategs,'name') === "Focus Area"
    ).map(
      parentCategs =>  _.filter(this.props.categories, {parent_id: _.get(parentCategs, 'id')}).map(
          category => ({
            id: category.id,
            label: category.name,
            parent: parentCategs.slug,
          })
        )
    )

    return _.get(categories, '[0]', []);
  }

 

  render = () => (
    <ProjectArchiveTemplate 
      {...this.props} 
      categoryFilters={this.props.query && this.props.query.split("|")}
      handleFilterChange={this.handleFilterChange}
      focusAreas={this.buildCategories()}
      caseStudies={this.getCaseStudies()}
      topics={this.props.topics}
      />
  )
}

const mapStateToProps = (state) => ({
  caseStudies: _.get(state.caseStudy, 'caseStudies'),
  parentCategories: _.get(state.caseStudy, 'parentCategories'),
  categories: _.get(state.caseStudy, 'categories'),
  topics: _.get(state.caseStudy, 'topics')
});

export default connect(mapStateToProps, {
  retrieveCaseStudies,
  retrieveCaseStudyCategories,
  retrieveCaseStudyTopics,
})(ProjectArchiveContainer);