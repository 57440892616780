import React from 'react';
import _ from 'lodash';
import { retrieveTeamBio } from '../actions/wp';
import { connect } from 'react-redux';

import TeamBioTemplate from '../templates/TeamBioTemplate';

class TeamBioContainer extends React.Component {

  componentDidMount = () => {
    this.props.retrieveTeamBio(this.props.slug);    
  }


  render = () => (
    <TeamBioTemplate {...this.props} />
  )
}

const mapStateToProps = (state) => ({
  activeTeamMember: _.get(state.team, 'activeTeamMember')
});

export default connect(mapStateToProps, {
  retrieveTeamBio
})(TeamBioContainer);